import { CardFacePreviewImage } from '../../global-types';
import { CardContextState, Dispatch, actionTypes } from './card-context-types';

export const setCardDataAction = (dispatch: Dispatch, cardData: CardContextState) => {
  dispatch({
    type: actionTypes.SET_CARD_DATA,
    payload: {
      cardData: cardData,
    },
  });
};

export const setActiveCardIndex = (dispatch: Dispatch, activeCardIndex: number) => {
  dispatch({
    type: actionTypes.SET_ACTIVE_CARD_INDEX,
    payload: {
      activeCardIndex: activeCardIndex,
    },
  });
};

export const setSlideCardIndex = (dispatch: Dispatch, slideCardIndex: number) => {
  dispatch({
    type: actionTypes.SET_SLIDE_CARD_INDEX,
    payload: { slideCardIndex },
  });
};

export const resetCardDataAction = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.RESET_CARD_DATA,
  });
};

export const setImages = (dispatch: Dispatch, images: CardFacePreviewImage[]) => {
  dispatch({
    type: actionTypes.SET_IMAGES,
    payload: {
      images,
    },
  });
};

export const setBtlyUrl = (dispatch: Dispatch, btlyUrl: string) => {
  dispatch({
    type: actionTypes.SET_BTLY_URL,
    payload: {
      btlyUrl,
    },
  });
};

export const setPreviewSlideIndex = (dispatch: Dispatch, previewSlideIndex: number) => {
  dispatch({
    type: actionTypes.SET_PREVIEW_SLIDE_INDEX,
    payload: { previewSlideIndex },
  });
};

export const setShowEmailConfirmation = (dispatch: Dispatch, showEmailConfirmation: boolean) => {
  dispatch({
    type: actionTypes.SET_SHOW_EMAIL_CONFIRMATION,
    payload: { showEmailConfirmation },
  });
};

export const setIsPreviewDialogOpen = (dispatch: Dispatch, isPreviewDialogOpen: boolean) => {
  dispatch({
    type: actionTypes.SET_IS_PREVIEW_DIALOG_OPEN,
    payload: { isPreviewDialogOpen },
  });
};

export const setSelectedPhotoZone = (
  dispatch: Dispatch,
  photoZoneId: string | null,
  uploadedImage?: fabric.Image | null,
) => {
  dispatch({
    type: actionTypes.SET_SELECTED_PHOTOZONE,
    payload: {
      photoZoneId,
      uploadedImage,
    },
  });
};
