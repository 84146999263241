import React from 'react';
import { PlaceHolderSettings } from '../../components/card-editor/card-editor-types';
import { CardFaceData, CardFacePreviewImage } from '../../global-types';

// Context State
export type CardContextState = {
  activeCardIndex: number;
  slideCardIndex: number;
  bPost: boolean;
  cardTemplateId: string | null;
  cardTemplateName: string;
  cardFacesList: CardFaceData[];
  cardFormat: string;
  cardType: string;
  cardCanvas: unknown;
  changesSinceLastSave: number;
  companyId: number;
  cultureId: number;
  currentZone:
    | {
        uploadedImage: fabric.Image | null;
        id: string | null;
      }
    | Record<string, never>;
  currentArea: null;
  currentAreaNotificationTriggered: boolean;
  defaultOptions: unknown;
  editableAreas: fabric.Rect[];
  fontPaths: string[];
  imageIsLoading: boolean;
  isChocolate: boolean;
  isExporting: boolean;
  isPhotozoneActive: boolean;
  isPostcard: boolean;
  isPostcardFromPhoto: boolean | undefined;
  isWooden: boolean;
  loginUrl: string;
  lockedFront: boolean;
  lockedBack: boolean;
  // photoZones: [];
  placeholderSettingsA: PlaceHolderSettings | Record<string, never>;
  placeholderSettingsB: PlaceHolderSettings | Record<string, never>;
  projectId: string;
  totalZones: number | null;
  tempText: string; // used for the dimmer
  textSettings: unknown;
  textPadding: number;
  textDefault: unknown[];

  // swipe data
  startPoint: {
    x: number | null;
    y: number | null;
  };

  endPoint: {
    x: number | null;
    y: number | null;
  };

  leftCameraEdge: null;
  rightCameraEdge: null;
  currentState: string;
  states: {
    create: unknown;
    edit: unknown;
    address: unknown;
  };
  coverPreviewUrl: string;

  selectedPhotoZone: {
    uploadedImage: fabric.Image | null;
    id: string | null;
  };

  images: CardFacePreviewImage[];
  btlyUrl: string;
  previewSlideIndex: number;
  showEmailConfirmation: boolean;
  isPreviewDialogOpen: boolean;
};

// Provider
export type CardProviderProps = {
  children: React.ReactNode;
};

// Actions
export enum actionTypes {
  SET_CARD_FACES = 'SET_CARD_FACES',
  SET_CARD_CANVAS_REF = 'SET_CARD_CANVAS',
  SET_CARD_DATA = 'SET_CARD_DATA',
  SET_CARD_FACE_CANVAS = 'SET_CARD_FACE_CANVAS',
  SET_ACTIVE_CARD_INDEX = 'SET_ACTIVE_CARD_INDEX',
  SET_SLIDE_CARD_INDEX = 'SET_SLIDE_CARD_INDEX',
  SET_ACTIVE_CANVAS_OBJECT = 'SET_ACTIVE_CANVAS_OBJECT',
  RESET_CARD_DATA = 'RESET_CARD_DATA',
  ADD_REMAINING_ZONE = 'ADD_REMAINING_ZONE',
  REMOVE_REMAINING_ZONE = 'REMOVE_REMAINING_ZONE',
  SET_SELECTED_PHOTOZONE = 'SET_SELECTED_PHOTOZONE',
  SET_IMAGES = 'SET_IMAGES',
  SET_BTLY_URL = 'SET_BTLY_URL',
  SET_PREVIEW_SLIDE_INDEX = 'SET_PREVIEW_SLIDE_INDEX',
  SET_SHOW_EMAIL_CONFIRMATION = 'SET_SHOW_EMAIL_CONFIRMATION',
  SET_IS_PREVIEW_DIALOG_OPEN = 'SET_IS_PREVIEW_DIALOG_OPEN',
}

export type CardAction =
  | SetCardFacesAction
  | SetCardCanvasRefAction
  | SetCardDataAction
  | SetActiveCardIndex
  | ResetCardDataAction
  | SetSelectedPhotoZoneAction
  | SetSlideCardIndex
  | SetImagesAction
  | SetBtlyUrlAction
  | SetPreviewSlideIndexAction
  | SetShowEmailConfirmationAction
  | SetIsPreviewDialogOpenAction;

export type SetSelectedPhotoZoneAction = {
  type: actionTypes.SET_SELECTED_PHOTOZONE;
  payload: {
    photoZoneId: string | null;
    uploadedImage?: fabric.Image | null;
  };
};
export type SetCardFacesAction = {
  type: actionTypes.SET_CARD_FACES;
  payload: {
    cardFaces: CardFaceData[];
  };
};

export type SetCardCanvasRefAction = {
  type: actionTypes.SET_CARD_CANVAS_REF;
  payload: {
    cardCanvas: unknown;
  };
};

export type SetCardDataAction = {
  type: actionTypes.SET_CARD_DATA;
  payload: {
    cardData: CardContextState;
  };
};

export type SetActiveCardIndex = {
  type: actionTypes.SET_ACTIVE_CARD_INDEX;
  payload: {
    activeCardIndex: number;
  };
};

export type SetSlideCardIndex = {
  type: actionTypes.SET_SLIDE_CARD_INDEX;
  payload: {
    slideCardIndex: number;
  };
};

export type ResetCardDataAction = {
  type: actionTypes.RESET_CARD_DATA;
};

export type SetImagesAction = {
  type: actionTypes.SET_IMAGES;
  payload: {
    images: CardFacePreviewImage[];
  };
};

export type SetBtlyUrlAction = {
  type: actionTypes.SET_BTLY_URL;
  payload: {
    btlyUrl: string;
  };
};

export type SetPreviewSlideIndexAction = {
  type: actionTypes.SET_PREVIEW_SLIDE_INDEX;
  payload: { previewSlideIndex: number };
};

export type SetShowEmailConfirmationAction = {
  type: actionTypes.SET_SHOW_EMAIL_CONFIRMATION;
  payload: { showEmailConfirmation: boolean };
};

export type SetIsPreviewDialogOpenAction = {
  type: actionTypes.SET_IS_PREVIEW_DIALOG_OPEN;
  payload: { isPreviewDialogOpen: boolean };
};

export type Dispatch = (action: CardAction) => void;

export type CreateContextProps = { cardState: CardContextState; cardDispatch: Dispatch } | undefined;
