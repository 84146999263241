import React, { Reducer, createContext, useReducer, useContext } from 'react';
import { initState } from './card-context-state';
import { CardContextState, CardAction, CardProviderProps, CreateContextProps, actionTypes } from './card-context-types';

export const CardContext = createContext<CreateContextProps>(undefined);

const cardReducer: Reducer<CardContextState, CardAction> = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_PHOTOZONE: {
      const selectedPhotoZone: CardContextState['selectedPhotoZone'] = {
        id: action.payload.photoZoneId,
        uploadedImage: action.payload.uploadedImage || null,
      };
      return {
        ...state,
        selectedPhotoZone,
      };
    }
    case actionTypes.SET_CARD_DATA: {
      return {
        ...state,
        ...action.payload.cardData,
      };
    }
    case actionTypes.SET_CARD_FACES: {
      return {
        ...state,
        cardFacesList: action.payload.cardFaces,
      };
    }
    case actionTypes.SET_CARD_CANVAS_REF: {
      return {
        ...state,
        cardCanvas: action.payload?.cardCanvas,
      };
    }
    case actionTypes.SET_ACTIVE_CARD_INDEX: {
      return {
        ...state,
        activeCardIndex: action.payload.activeCardIndex,
      };
    }
    case actionTypes.SET_SLIDE_CARD_INDEX: {
      return {
        ...state,
        slideCardIndex: action.payload.slideCardIndex,
      };
    }
    case actionTypes.RESET_CARD_DATA: {
      return {
        ...initState,
      };
    }
    case actionTypes.SET_IMAGES:
      return {
        ...state,
        images: action.payload.images,
      };
    case actionTypes.SET_BTLY_URL:
      return {
        ...state,
        btlyUrl: action.payload.btlyUrl,
      };
    case actionTypes.SET_PREVIEW_SLIDE_INDEX:
      return {
        ...state,
        previewSlideIndex: action.payload.previewSlideIndex,
      };
    case actionTypes.SET_SHOW_EMAIL_CONFIRMATION:
      return {
        ...state,
        showEmailConfirmation: action.payload.showEmailConfirmation,
      };
    case actionTypes.SET_IS_PREVIEW_DIALOG_OPEN:
      return {
        ...state,
        isPreviewDialogOpen: action.payload.isPreviewDialogOpen,
      };
    default: {
      throw new Error(`Unhandled action type in card-context`);
    }
  }
};

export const CardContextProvider = ({ children }: CardProviderProps) => {
  const [cardState, cardDispatch] = useReducer(cardReducer, initState);

  const value = { cardState, cardDispatch };
  return <CardContext.Provider value={value}>{children}</CardContext.Provider>;
};

// Hooks
export const useCardContext = () => {
  const context = useContext(CardContext);
  if (context === undefined) {
    throw new Error('useCard must be used within CardProvider');
  }

  return context;
};
