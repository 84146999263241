// Views will go here
import React, { ReactElement, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Location, Action } from 'history';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { IconNames } from '@hallmark/web.core.buttons.icon-button';
import { SEO } from '@hallmark/web.page-components.seo';
import { useCustomBordersAndShadows } from '../../components/canvas-custom-config/hooks/useCustomBordersAndShadows';
import { DrawerContainer } from '../../components/card-controls/drawer-container';
import { CardEditor } from '../../components/card-editor';
import { CardSelectBar } from '../../components/card-select-bar';
import { Layout } from '../../components/layout';
import { useAnalyticsContext } from '../../context/analytics-context';
import { useAppContext } from '../../context/app-context';
import { hideLoadingScreen, setIsSaving } from '../../context/app-context/app-context-actions';
import { useCardContext, resetCardDataAction } from '../../context/card-context';
import { useInitializationDataContext } from '../../context/data-context';
import { RegionalCodesList } from '../../global-types';
import {
  useActiveCanvas,
  useLineItemUUID,
  useHistoryBlock,
  useExitUrl,
  useSaveProject,
  useSanitizeSearchUrl,
  useIsDigitalGreeting,
  useFeatureFlags,
  useIsSignAndSend,
} from '../../hooks';
import { config } from '../../regional-config';
import { isLoggedIn, isToolbarVisible } from '../../utils';
import { PodMainPageLoaded, ViewItem } from '../../utils/analytics/analytics-types';
import { pushPodMainPageLoaded, pushViewItem } from '../../utils/analytics/analytics-utils';
import { isAnyOfRegions } from '../../utils/utility';
import styles from './editor.module.scss';
import { useStartProject } from './hooks';
import { useEditorNavigation } from './hooks/useEditorNavigation';
import { getClassEditorOverlapStyle } from './utils/get-class-editor-overlap-style';

export const EditorView = (): ReactElement => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { cardState, cardDispatch } = useCardContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const canvasParentRef = useRef<HTMLDivElement>(null);
  const slideIndex = cardState.slideCardIndex;
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);
  const [triggerPageLoadTracking, setTriggerPageLoadTracking] = useState(false);
  const [previewGenerated, setPreviewGenerated] = useState(false);
  const isDataLoaded = useRef<boolean>(false);
  const { t } = useTranslation();
  const activeCanvas = useActiveCanvas();
  const isSignAndSend = useIsSignAndSend();
  const [isEditorToolbarVisible, setIsEditorToolbarVisible] = useState(false);
  const textEditorDrawerType = config?.cardEditor?.textEditorDrawerType;
  const footerContainerClass = config?.cardSelectBar?.containerClass;

  if (config?.editor?.hasCustomBordersAndShadows) {
    useCustomBordersAndShadows((activeCanvas && activeCanvas.current) || null);
  }

  useEffect(() => {
    if (cardState) {
      const mainPageEventData: Omit<PodMainPageLoaded, 'event_id'> = {
        event: 'pod_main_page_loaded',
        front_editable: cardState?.cardFacesList[0]?.isEditable || false,
        inside_editable: cardState?.cardFacesList[1]?.isEditable || false,
        item_id: cardState?.projectId,
        item_name: cardState?.cardTemplateName,
        card_template_id: cardState?.cardTemplateId,
      };
      pushPodMainPageLoaded(mainPageEventData);

      const viewItemEventData: Omit<ViewItem, 'event_id'> = {
        event: 'view_item',
        journey_type: 'pod_editor',
      };
      pushViewItem(viewItemEventData);
    }
  }, [cardState]);

  const {
    appState: {
      isProjectSaved,
      isSystemErrorOpen,
      cardFacesLoaded,
      isTextDrawerOpen,
      isImageUploadDrawerOpen,
      isImageUploadToPhotoZoneOpen,
    },
    appDispatch,
  } = useAppContext();
  const { trackClickPreviewMode, trackStartFlow, firstElementSelected, trackPageNavigation } = useAnalyticsContext();

  const isCreateView = useMemo(() => pathname.indexOf('create') >= 0, [pathname]);
  const pageTitle = useMemo(
    () => (isCreateView ? t('editorView.pageTitle') : t('editorView.editPageTitle')),
    [isCreateView],
  );
  const isDigitalGreeting = useIsDigitalGreeting();
  const { SAVED_PROJECTS, DYNAMIC_TEXBOX_DRAWER, SAS_TOOLBAR_TOGGLE } = useFeatureFlags();
  const { saveProject } = useSaveProject();
  useLineItemUUID();
  useExitUrl();
  const { isSearchUrlSanitized } = useSanitizeSearchUrl();
  const { activeCardIndex } = cardState;

  const currentCardFace = cardState.cardFacesList[cardState.activeCardIndex];
  const isFrontFace = currentCardFace?.type === 'front';
  const activeCanvasObject = activeCanvas?.current?.getActiveObject();

  const editableFaces = useMemo(
    () => cardState.cardFacesList.filter((face) => face.editorDisplayIndicator),
    [cardState.cardFacesList],
  );
  const { buttonProps, handleIndexChange } = useEditorNavigation();

  // TODO: Move this to a custom hook to clean the component
  /**
   * First function to be called when user open the page, which
   * kicks off loading of project assets
   */
  const startProject = useStartProject(isDataLoaded, [pathname, isSearchUrlSanitized]);

  const onChangeIndex = (newIndex: number) => {
    if (newIndex === editableFaces.length - 1) {
      const newActiveCardIndex = editableFaces[slideIndex + 1]?.faceNumber - 1;
      handleIndexChange(newIndex, newActiveCardIndex);
    } else {
      handleIndexChange(newIndex);
    }
  };

  // Function to be passed as parameter in useHistoryBlock hook. Blocks the POP action and saves the project before moving forward
  const handleBrowserNavigation = useCallback(
    (location: Location, action: Action) => {
      if (action === 'POP' && !isProjectSaved) {
        saveProject()?.then(() => {
          history.push(location);
        });
        return false;
      }
    },
    [saveProject, isProjectSaved],
  );

  const handleClickOutsideCanvas = (ev: MouseEvent | TouchEvent) => {
    const target = ev.target as HTMLElement;
    if (target?.nodeName !== 'CANVAS') {
      activeCanvas?.current?.discardActiveObject().requestRenderAll();
    }
  };

  const autoSaveProject = async () => {
    if (SAVED_PROJECTS) {
      setIsSaving(appDispatch, true);
      // Generate previews if we don't have any
      if (initializedData?.project_type_code !== 'S' && !previewGenerated) {
        await saveProject({
          shouldRestoreCanvas: true,
          showLoadingScreen: false,
          generateFrontPreview: true,
        });
        setPreviewGenerated(true);
      } else {
        await saveProject({
          shouldRestoreCanvas: true,
          showLoadingScreen: false,
          generateFrontPreview: false,
        });
      }
      setIsSaving(appDispatch, false);
    }
  };

  useHistoryBlock(history, handleBrowserNavigation);

  useEffect(() => {
    if (isDataLoaded.current) {
      return;
    }
    // reset card state re-load canvas if moving back to edit
    resetCardDataAction(cardDispatch);
    // initialize or load project and save the response in corresponding context
    startProject();
  }, [startProject, isDataLoaded.current]);

  useEffect(() => {
    // We save this so we can load the project from the address view if the page is refreshed
    sessionStorage.setItem('lastProjectLoaded', initializedData?.project_id || '');
  }, [initializedData?.project_id]);

  useEffect(() => {
    // Additional saved info in case of address view refresh
    sessionStorage.setItem('lastProjectTypeCode', initializedData?.project_type_code || '');
  }, [initializedData?.project_type_code]);

  useEffect(() => {
    const backgroundImagesAreLoaded = activeCanvas?.current?.backgroundImage !== null;
    if (cardFacesLoaded && isLoggedIn() && !initializedData?.assets?.[0] && backgroundImagesAreLoaded) {
      autoSaveProject();
    }
  }, [cardFacesLoaded, initializedData?.assets]);

  useEffect(() => {
    if (cardFacesLoaded) {
      hideLoadingScreen(appDispatch);
    }
  }, [cardFacesLoaded]);

  useEffect(() => {
    setTriggerPageLoadTracking(true);
  }, []);

  useEffect(() => {
    if (initializedData?.product_id && isInitialPageLoad && triggerPageLoadTracking) {
      if (isCreateView) {
        trackStartFlow(isDigitalGreeting ? 'Digital Greetings' : 'Print on Demand');
      }
      setIsInitialPageLoad(false);
    }
  }, [initializedData, isInitialPageLoad, triggerPageLoadTracking, isCreateView]);

  useEffect(() => {
    // this is set to track the slide changes before any elements are edited or selected
    if (!firstElementSelected.current) {
      trackClickPreviewMode();
    } else {
      trackPageNavigation();
    }
  }, [activeCardIndex]);

  useEffect(() => {
    const clickOrTouch = 'ontouchstart' in window ? 'touchstart' : 'click';
    canvasParentRef?.current?.addEventListener(clickOrTouch, handleClickOutsideCanvas);
    if (activeCanvas) {
      setIsEditorToolbarVisible(isToolbarVisible(activeCanvas, isSignAndSend));
    }
    return () => canvasParentRef?.current?.removeEventListener(clickOrTouch, handleClickOutsideCanvas);
  }, [activeCanvas]);

  const seoMetaProperties = [
    {
      property: 'og:title',
      content: pageTitle,
    },
    {
      property: 'og:site_name',
      content: pageTitle,
    },
  ];

  /** Get the class for the editor based on the drawer state */
  const editorClass = getClassEditorOverlapStyle(
    styles,
    isTextDrawerOpen,
    activeCanvasObject,
    isMobile,
    isFrontFace,
    isImageUploadDrawerOpen,
    isImageUploadToPhotoZoneOpen,
    textEditorDrawerType,
    DYNAMIC_TEXBOX_DRAWER,
  );

  return (
    <Layout hideToolbar={!isEditorToolbarVisible && SAS_TOOLBAR_TOGGLE}>
      <SEO title={pageTitle} description={`${t('editorView.seo')}`} metaProperties={seoMetaProperties} />
      <DrawerContainer />
      <div className={editorClass}>
        <div className={styles['workspace-panel']} data-testid="Workspace" id="Workspace" ref={canvasParentRef}>
          <CardEditor cardFaceIndex={slideIndex} />
        </div>
        <div className={classNames(styles[footerContainerClass ?? 'footer'])}>
          {cardState?.cardFacesList && (
            <div className={styles['card-select-bar']}>
              <CardSelectBar
                faces={editableFaces}
                slideIndex={slideIndex}
                handleIndexChange={onChangeIndex}
                mode="edit"
              />
            </div>
          )}
          {!isSystemErrorOpen && isAnyOfRegions([RegionalCodesList.uk, RegionalCodesList.us]) && (
            <Button
              {...buttonProps}
              endIcon={{ name: IconNames.ArrowsTailRightBold, color: 'white' }}
              mode={ButtonModes.Primary}
              testId="address-button"
              addClass={styles['address-button']}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
