import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { CEBrandColors } from '@hallmark/web.styles.colors';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { useAppContext } from '../../../context/app-context';
import { setActiveCardIndex, useCardContext } from '../../../context/card-context';
import { setIsPreviewDialogOpen, setPreviewSlideIndex } from '../../../context/card-context/card-context-actions';
import { CardType, RegionalCodesList } from '../../../global-types';
import { useIsOneToMany, useSaveProjectDialog } from '../../../hooks';
import { useEmailOrderProcess } from '../../../hooks/useEmailOrderProcess';
import { config } from '../../../regional-config';
import { isLoggedIn } from '../../../utils';
import { useEditorNavigation } from '../../../views/editor/hooks/useEditorNavigation';
import { PreviewDialog } from '../../preview-dialog/preview-dialog';
import { QuantityLink } from '../../quantity-link';
import { SaveProjectLink } from '../../save-project-link';
import { ScreenLoader } from '../../screen-loader';
import { SignInDialog } from '../../sign-in-dialog/sign-in-dialog';
import { CEHeaderLayoutProps } from '../print-on-demand-header-types';
import styles from '../print-on-demand-header.module.scss';

const CEHeaderLayout = ({ closeButtonRef, onCloseButtonClick, onLogoIconClick, classes }: CEHeaderLayoutProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    appState: { isSystemErrorOpen, signInDialogOpen, isSaving },
  } = useAppContext();
  const { cardState, cardDispatch } = useCardContext();

  const showProjectSummary = useIsOneToMany();
  const { buttonProps } = useEditorNavigation();

  const { images, btlyUrl, previewSlideIndex, isPreviewDialogOpen } = cardState;
  const projectTypeCode = sessionStorage.getItem('lastProjectTypeCode');
  const isDigitalGreetings = projectTypeCode === CardType.DG;

  const isPreviewPage = pathname.includes('/preview');
  const { pageNavigationButtonClicked } = useAnalyticsContext();
  const { sendEmailNow, isSendingEmail } = useEmailOrderProcess({ images, btlyUrl });
  const updatePreviewSlideIndex = (index: number) => setPreviewSlideIndex(cardDispatch, index);
  const updateIsPreviewDialogOpen = (isOpen: boolean) => setIsPreviewDialogOpen(cardDispatch, isOpen);

  const handleOrder = () => {
    if (previewSlideIndex < images.length - 1) {
      pageNavigationButtonClicked.current = 'button';
      setAnalyticsIndex(previewSlideIndex + 1);
    } else {
      isDigitalGreetings ? sendEmailNow() : updateIsPreviewDialogOpen(true);
    }
  };

  const previewButtonProps = {
    click: handleOrder,
    children: t('editorView.lastFace'),
    disabled: isSendingEmail,
  };

  const currentButtonProps = isPreviewPage ? previewButtonProps : buttonProps;

  const hasSavedProject = localStorage.getItem('hasSavedProject') !== 'true';

  const { isAbandoningProject, onCloseDialog, onSignIn, onAbandonProject, onSaveProject, isExitFromXButton } =
    useSaveProjectDialog();

  const isUserLoggedIn = isLoggedIn();

  const setAnalyticsIndex = (index: number) => {
    updatePreviewSlideIndex(index);
    setActiveCardIndex(cardDispatch, index);
  };

  return (
    <div className={classes}>
      <Button
        ref={closeButtonRef}
        mode={ButtonModes.TextLink}
        click={onCloseButtonClick}
        addClass={styles['back-button']}
      >
        {t('header.back')}
      </Button>

      {showProjectSummary ? <QuantityLink /> : <div className={styles['quantity-link-placeholder']} />}

      <Button click={onLogoIconClick} addClass={styles.logo} testId="crown-icon-button" tabIndex={0}>
        <img src={config?.header?.logo} alt="Hallmark logo" />
      </Button>

      <div className={styles['save-project-modern']}>
        {isUserLoggedIn && <SaveProjectLink />}

        {!isSystemErrorOpen && (
          <Button
            {...currentButtonProps}
            endIcon={{ name: IconNames.ArrowsTailRightBold, color: 'white' }}
            mode={ButtonModes.Primary}
            testId="address-button"
            addClass={styles['address-button']}
          />
        )}
      </div>

      {hasSavedProject && (
        <ScreenLoader isVisible={isSaving}>
          <Typography variant={TypographyVariants.Headline3}>{t('header.screenLoaderMessagePart1')}</Typography>
          <Typography variant={TypographyVariants.Headline3} color={CEBrandColors.Purple}>
            {t('header.screenLoaderHighlight')}
          </Typography>
          <Typography variant={TypographyVariants.Headline3}>{t('header.screenLoaderMessagePart2')}</Typography>
        </ScreenLoader>
      )}

      {signInDialogOpen && (
        <SignInDialog
          isOpen={signInDialogOpen}
          close={onCloseDialog}
          isLoggedIn={isUserLoggedIn}
          isAbandoningProject={isAbandoningProject}
          onSaveProject={(data) => onSaveProject(data, isExitFromXButton)}
          onSignIn={onSignIn}
          onAbandonProject={onAbandonProject}
          theme={config?.header?.saveDialogTheme || RegionalCodesList.ce}
        />
      )}

      {isPreviewDialogOpen && (
        <PreviewDialog
          isOpen={isPreviewDialogOpen}
          onClose={() => updateIsPreviewDialogOpen(false)}
          handleSubmit={buttonProps.click}
        />
      )}
    </div>
  );
};

export default CEHeaderLayout;
