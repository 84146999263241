import { useEffect } from 'react';
import { setExitUrl, useAppContext } from '../context/app-context';
import { config } from '../regional-config';
import { getMonolithUrl } from '../utils/utility';
import { useQueryParams } from './useQueryParams';

const monolithUrl = getMonolithUrl() ?? '';

export const useExitUrl = () => {
  const queryParams = useQueryParams();
  const { appDispatch } = useAppContext();

  useEffect(() => {
    const monolithUrlObj = new URL(monolithUrl);
    const monolithOrigin = monolithUrlObj.origin;
    const skuReturn = queryParams.get('returnsku');
    const cartReturn = queryParams.get('fromCart') || queryParams.get('fromcart');
    const returnTo = queryParams.get('returnTo') || queryParams.get('returnto');

    // eslint-disable-next-line no-console
    console.log('Query Params:', { returnTo, skuReturn, cartReturn });
    // eslint-disable-next-line no-console
    console.log('Monolith Origin:', monolithOrigin);

    if (returnTo) {
      const exitUrl = `${monolithOrigin}/${returnTo}`;
      // eslint-disable-next-line no-console
      console.log('Setting exitUrl to returnTo:', exitUrl);
      setExitUrl(appDispatch, exitUrl);
      return;
    }
    if (skuReturn) {
      const exitUrl = `${monolithOrigin}${config.exitUrl.cardsPath}/${skuReturn}`;
      // eslint-disable-next-line no-console
      console.log('Setting exitUrl to skuReturn:', exitUrl);
      setExitUrl(appDispatch, exitUrl);
      return;
    }
    if (cartReturn) {
      const exitUrl = `${monolithOrigin}${config.exitUrl.cartPath}/`;
      // eslint-disable-next-line no-console
      console.log('Setting exitUrl to cartReturn:', exitUrl);
      setExitUrl(appDispatch, exitUrl);
      return;
    }
    if (window.document.referrer.includes(monolithOrigin)) {
      // eslint-disable-next-line no-console
      console.log('Setting exitUrl to document.referrer:', window.document.referrer);
      setExitUrl(appDispatch, window.document.referrer);
      return;
    }
    // eslint-disable-next-line no-console
    console.log('Setting exitUrl to monolithUrl:', monolithUrl);
    setExitUrl(appDispatch, monolithUrl);
  }, [queryParams]);
};
