import { ButtonModes } from '@hallmark/web.core.buttons.button';
import { ButtonGroupVariant } from '@hallmark/web.core.buttons.button-group';
import { DrawerLayoutVariants } from '@hallmark/web.core.feedback.drawer';
import { RangeVariants } from '@hallmark/web.core.forms.range';
import { TypographyVariants } from '@hallmark/web.core.typography.typography';
import { alignmentDrawerBtnList2 } from '@hallmark/web.page-components.print-on-demand.text-editor.alignment-drawer';
import { RangeValuePosition } from '@hallmark/web.page-components.print-on-demand.text-editor.size-drawer';
import { MoreButtonPosition } from '@hallmark/web.page-components.print-on-demand.text-editor.text-color-drawer/dist/text-color-drawer-types';
import { TextOptionDrawerVariant } from '@hallmark/web.page-components.print-on-demand.text-editor.text-option-drawer';
import { CEBrandColors } from '@hallmark/web.styles.colors';
import CEAddPhotoIcon from '../../assets/add-photo-icon-ce.svg';
import AnimatedCrown from '../../assets/animated-crown.gif';
import CEDeleteIcon from '../../assets/ce-delete.svg';
import CERectangleIcon from '../../assets/ce-rectangle.svg';
import CERotateIcon from '../../assets/ce-rotate.svg';
import CEScaleIcon from '../../assets/ce-scale.svg';
import CEEditIcon from '../../assets/edit-icon-ce.svg';
import HallmarkLogo from '../../assets/logo.svg';
import CEUserZoneAddPhotoIcon from '../../assets/user-zone-add-photo-icon-ce.svg';
import CEUserZoneAddStickerIcon from '../../assets/user-zone-add-sticker-icon-ce.svg';
import CEUserZoneAddTextIcon from '../../assets/user-zone-add-text-icon-ce.svg';
import { HeaderVariants } from '../../components/print-on-demand-header/print-on-demand-header-types';
import { RegionalCodesList } from '../../global-types';
import { TextEditorDrawerType } from '../../global-types/regions-list';
import colorsList from '../../styles/util.scss';
import { RegionalSchema } from '../regional-config-types';

export const configCE: RegionalSchema = {
  gtm: {
    id: 'GTM-KCFV3VW',
  },
  editor: {
    hasCustomBordersAndShadows: true,
  },
  screen: {
    imageLoader: AnimatedCrown,
    defaultFontId: 1500,
    defaultFontFamily: 'fontid-1500',
  },
  cardEditor: {
    faceTypeText: false,
    textEditorDrawerType: TextEditorDrawerType.Bottom,
    photoZoneBackgroundColor: colorsList[CEBrandColors.Purple10],
    editableAreaDefaultStrokeColor: colorsList[CEBrandColors.Pink],
    editableAreaDefaultFillColor: colorsList[CEBrandColors.Pink10],
    foldLineColor: colorsList[CEBrandColors.LightGray],
  },
  cardSelectBar: {
    containerClass: 'footer-variant',
  },
  carousel: {
    hasNavigationButtons: false,
  },
  navigation: {
    useAlternativeExitLogic: true,
  },
  saveProjects: {
    saveButton: {
      type: 'SaveButton',
    },
    hasSignInDialog: true,
    hasProjectNameFeature: false,
  },
  loader: {
    useCrownLoader: true,
  },
  preview: {
    hasEmailButton: false,
  },
  textOptionDrawer: {
    shouldRenderFooter: false,
    hasHeaderContent: true,
    renderIconVariant: TextOptionDrawerVariant.Variant2,
    hasChildIcon: false,
    layoutVariant: DrawerLayoutVariants.Limited,
    hideCloseButton: false,
    showVeil: false,
  },
  alignmentDrawer: {
    btnList: alignmentDrawerBtnList2,
    layoutVariant: DrawerLayoutVariants.Absolute,
    actionButtonMode: ButtonModes.Quaternary,
    cancelButtonMode: ButtonModes.Quaternary,
    hasActionButton: true,
    buttonGroupMode: ButtonGroupVariant.Tertiary,
  },
  textColorDrawer: {
    moreButtonPosition: MoreButtonPosition.LEFT,
    ButtonGroupVariant: ButtonGroupVariant.Secondary,
    hasPurpleShadowEffect: true,
    actionButtonMode: ButtonModes.Quaternary,
    cancelButtonMode: ButtonModes.Quaternary,
    hasActionButton: true,
    hasCancelButton: false,
    layoutVariant: DrawerLayoutVariants.Absolute,
  },
  fontDrawer: {
    hasActionButton: true,
    layoutVariant: DrawerLayoutVariants.Absolute,
    actionButtonMode: ButtonModes.Quaternary,
    cancelButtonMode: ButtonModes.Quaternary,
  },
  sizeDrawer: {
    hasCancelButton: false,
    hasActionButton: true,
    actionButtonMode: ButtonModes.Quaternary,
    layoutVariant: DrawerLayoutVariants.Absolute,
    rangeVariant: RangeVariants.Secondary,
    rangeValuePosition: RangeValuePosition.Top,
  },
  orderDrawer: {
    hasCancelButton: false,
    hasActionButton: true,
    actionButtonMode: ButtonModes.Quaternary,
    cancelButtonMode: ButtonModes.Quaternary,
    labelTypographyVariant: TypographyVariants.BodyBold,
    layoutVariant: DrawerLayoutVariants.Absolute,
    rangeVariant: RangeVariants.Secondary,
  },
  scaleDrawer: {
    hasCancelButton: false,
    hasActionButton: true,
    actionButtonMode: ButtonModes.Quaternary,
    cancelButtonMode: ButtonModes.Quaternary,
    labelTypographyVariant: TypographyVariants.BodyBold,
    labelValueTypographyVariant: TypographyVariants.Body,
    layoutVariant: DrawerLayoutVariants.Absolute,
    rangeVariant: RangeVariants.Secondary,
  },
  rotateDrawer: {
    hasCancelButton: false,
    hasActionButton: true,
    actionButtonMode: ButtonModes.Quaternary,
    cancelButtonMode: ButtonModes.Quaternary,
    labelTypographyVariant: TypographyVariants.BodyBold,
    layoutVariant: DrawerLayoutVariants.Absolute,
    rangeVariant: RangeVariants.Secondary,
    hasRangeLabel: false,
    rangeLabelColor: CEBrandColors.Purple,
    rangeLabelVariant: TypographyVariants.BodyBold,
    rangeLabelSymbol: 'degree',
    enableTickInteraction: true,
  },
  colorPalleteDrawer: {
    titleTypographyVariant: TypographyVariants.Headline5,
  },
  imageEditDrawer: {
    hasFooterContent: false,
    headerContentType: TextOptionDrawerVariant.Variant2,
    hasLayoutVariant: true,
    hideCloseButton: false,
    hasHeaderContent: true,
    applyButtonMode: ButtonModes.Quaternary,
    cancelButtonMode: ButtonModes.Quaternary,
    layoutVariant: DrawerLayoutVariants.Limited,
  },
  imageUploadDrawer: {
    hadAlternativeLoader: true,
  },
  header: {
    saveDialogTheme: RegionalCodesList.ce,
    variant: HeaderVariants.CE,
    logo: HallmarkLogo,
  },
  wamDrawer: {
    hasTitle: true,
    buttonModes: ButtonModes.Secondary,
    buttonTypographyVariant: TypographyVariants.BodyBold,
  },
  userZones: {
    stokeAndFillHide: true,
    hasTextButton: true,
    addTextIcon: CEUserZoneAddTextIcon,
    addTextIconOffset: -119,
    hasPhotoButton: true,
    addPhotoIcon: CEUserZoneAddPhotoIcon,
    addPhotoIconOffset: 0,
    hasWAMButton: false,
    addWAMIcon: null,
    addWAMIconOffset: 0,
    hasStickerButton: true,
    addStickerIcon: CEUserZoneAddStickerIcon,
    addStickerIconOffset: 119,
    hasInstructions: false,
  },
  photoDrawer: {
    hasTitle: true,
    hasSubtitle: true,
    titleTypographyVariant: TypographyVariants.Headline3, //5
    subtitleTypographyVariant: TypographyVariants.Body,
    buttonModes: ButtonModes.Secondary,
    buttonTypographyVariant: TypographyVariants.BodyBold,
    uploadInstructionsPosition: 'top',
  },
  instructionStep: {
    titleVariant: TypographyVariants.Headline5,
    titleColor: CEBrandColors.Purple,
    descriptionColor: CEBrandColors.Anthracite,
  },
  imagesUpload: {
    maxFileSize: 51 * 1024 * 1024, // 51MB
    maxFilesCount: 20,
  },
  customControls: {
    renderImageControlIcon: true,
    crop: {
      hasRotateIcon: true,
      hasMiddleControls: true,
      hasCornerControls: true,
      iconSize: 15,
    },
    image: {
      hideMiddleControls: false,
      padding: 10,
    },
    icons: {
      deleteIcon: CEDeleteIcon,
      rotateIcon: CERotateIcon,
      scaleIcon: CEScaleIcon,
      rectangleIcon: CERectangleIcon,
      editIcon: CEEditIcon,
      addPhotoIcon: CEAddPhotoIcon,
    },
    mappingIcon: {
      region: RegionalCodesList.ce,
    },
    possitions: {
      delete: {
        x: 0.5,
        y: -0.5,
        offsetY: 0,
      },
      rotate: {
        x: 0,
        y: -0.7,
        offsetX: 0,
        offsetY: 0,
        withConnection: true,
      },
      resize: {
        x: 0.5,
        y: 0.5,
        offsetY: 0,
        withConnection: true,
      },
    },
    hasBorders: false,
    controlsVisibility: {
      rotateControl: true,
      deleteControl: true,
      resizeControl: true,
    },
    controlsPositionsTextVisibility: {
      mr: false,
      ml: false,
      mt: false,
      mb: false,
      bl: false,
      mtr: false,
      tr: false,
      tl: false,
      br: true,
    },
    renderImageIconSize: 1.5,
    controlBehaviors: {
      deleteControl: 'sizeTextIcon',
      resizeControl: 'sizeTextIcon',
      rotateControl: 'sizeTextIcon',
    },
  },
  canvas: {
    textColor: colorsList[CEBrandColors.Anthracite],
  },
  tips: {
    pagePath: '/inspiratie-teksten.html',
  },
  dialog: {
    useAlternativeDialog: true,
    cancelButtonMode: ButtonModes.Secondary,
    applyButtonMode: ButtonModes.Primary,
    applyTypographyVariant: TypographyVariants.BodyBold,
    cancelTypographyVariant: TypographyVariants.BodyBold,
  },
  toast: {
    variant: 'CE',
  },
  scssStyles: {
    appBackground: colorsList[CEBrandColors.Ochre20],
    headerBackgroundMobile: 'transparent',
    loaderBackground: colorsList[CEBrandColors.Ochre7],
    orderDrawerMaxWidth: '878px',
    orderDrawerRangeLabelPadding: '0 0 12px 0',
    scaleDrawerMaxWidth: '878px',
    wamDrawerImageWrapperFlex: '0 0 80px',
    wamDrawerImageWrapperFlexMobile: '0 0 64px',
    wamDrawerImageBorderRadius: '4px',
    wamDrawerImageOverflow: 'hidden',
    wamDrawerBadgeRight: '0',
    wamDrawerBadgeLeft: '0',
    wamDrawerBadgeMargin: 'auto',
    wamDrawerBadgeOpacity: '1',
    previewViewPadding: '0',
    previewViewPaddingMobile: '0 1.5rem 1.5rem 1rem',
    previewViewCardPadding: '2.5rem 0 1.5rem 0',
    carouselImageDotContainerPadding: '2.5rem 0',
    previewViewNavigationButtonsPosition: 'fixed',
    previewViewNavigationButtonsBottom: '5.375rem',
    previewViewNavigationButtonsLeft: '0',
    previewViewNavigationButtonsRight: '0',
    previewViewNavigationButtonsPadding: '0 3.5rem',
    previewViewNavigationButtonsBackground: 'transparent',
    previewViewNavigationButtonsZIndex: '0',
  },
  exitUrl: {
    cardsPath: '/kaarten',
    cartPath: '/winkelmand',
  },
};
