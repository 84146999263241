import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { useAppContext } from '../../../context/app-context';
import { setActiveCardIndex, useCardContext } from '../../../context/card-context';
import { setIsPreviewDialogOpen, setSlideCardIndex } from '../../../context/card-context/card-context-actions';
import { useInitializationDataContext } from '../../../context/data-context';
import { useActiveCanvas, useIsOneToMany } from '../../../hooks';
import { getCardFaceLabel, isLoggedIn } from '../../../utils';
import { PodInsideCardLoaded } from '../../../utils/analytics/analytics-types';
import { pushPodInsideCardLoaded } from '../../../utils/analytics/analytics-utils';
import { getTextAndImageObjects } from '../../../utils/canvas';
import { useHandleProjectDone } from './useHandleProjectDone';
import { useOnCardFaceIndexChange } from './useOnCardFaceIndexChange';
import { useUserZonesValidation } from './useUserZonesValidation';

export const useEditorNavigation = () => {
  const [beforeEditingFaceJson, setBeforeEditingFaceJson] = useState<string | null>(null);
  const [isFirstCheck, setIsFirstCheck] = useState(true);
  const { cardState, cardDispatch } = useCardContext();
  const {
    appState: { cardFacesLoaded },
  } = useAppContext();
  const { t } = useTranslation();
  const isOneToMany = useIsOneToMany();
  const activeCanvas = useActiveCanvas();
  const { onCardFaceIndexChange } = useOnCardFaceIndexChange();
  const { pageNavigationButtonClicked } = useAnalyticsContext();
  const { skipValidation, validateUserZones, uneditedZones } = useUserZonesValidation();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();

  const slideIndex = cardState.slideCardIndex;

  const editableFaces = useMemo(
    () => cardState.cardFacesList.filter((face) => face.editorDisplayIndicator),
    [cardState.cardFacesList],
  );
  const updateIsPreviewDialogOpen = (isOpen: boolean) => setIsPreviewDialogOpen(cardDispatch, isOpen);

  const handleIndexChange = useCallback(
    (newIndex: number, newActiveCardIndex?: number) => {
      const indexChangeOptions = {
        activeCanvas,
        editableFaces,
        slideIndex,
        beforeEditingFaceJson,
        isLoggedIn: isLoggedIn(),
      };
      onCardFaceIndexChange(indexChangeOptions);
      setActiveCardIndex(cardDispatch, newActiveCardIndex || newIndex);
      setSlideCardIndex(cardDispatch, newIndex);
    },
    [cardDispatch, editableFaces, slideIndex, beforeEditingFaceJson],
  );

  const handleProjectDone = useHandleProjectDone(
    skipValidation,
    validateUserZones,
    uneditedZones,
    isOneToMany,
    handleIndexChange,
  );

  const isOnLastFace = slideIndex === editableFaces.length - 1;

  const handleIncrementSlideIndex = () => {
    pageNavigationButtonClicked.current = 'Edit Button';
    const newActiveCardIndex = editableFaces[slideIndex + 1].faceNumber - 1;
    handleIndexChange(slideIndex + 1, newActiveCardIndex);

    if (cardState?.activeCardIndex === 1) {
      const insideCardLoadedEventData: Omit<PodInsideCardLoaded, 'event_id'> = {
        event: 'pod_inside_card_loaded',
      };
      pushPodInsideCardLoaded(insideCardLoadedEventData);
    }
  };

  const getNextButtonText = () => {
    const nextFace = editableFaces[slideIndex + 1];

    if (nextFace) {
      return t('editorView.nextFace', { face: getCardFaceLabel(nextFace.type) });
    }

    return t('editorView.lastFace');
  };

  const handleProjectDoneWithCheck = () => {
    const isCanvasEmpty = () => {
      const filteredObjects = getTextAndImageObjects(activeCanvas?.current as fabric.Canvas);
      return filteredObjects.length < 1;
    };

    if (isFirstCheck && isCanvasEmpty()) {
      updateIsPreviewDialogOpen(true);
      setIsFirstCheck(false);
    } else {
      handleProjectDone();
    }
  };

  const buttonProps = {
    click: isOnLastFace ? handleProjectDoneWithCheck : handleIncrementSlideIndex,
    children: getNextButtonText(),
    disabled: isOnLastFace && initializedData?.project_type_code === 'S' ? !validateUserZones() : false,
  };

  useEffect(() => {
    // If cardFaces haven't loaded yet, exit the code
    if (editableFaces.length <= 0) {
      return;
    }

    // Check if frontFace canvas has loaded
    const isFrontFaceLoaded = editableFaces[0]?.canvas !== undefined;

    if (!beforeEditingFaceJson && isFrontFaceLoaded && cardFacesLoaded) {
      const editableObjectsJson = JSON.stringify(editableFaces[0].canvas.current?.toJSON().objects);
      setBeforeEditingFaceJson(editableObjectsJson);
    }
    if (beforeEditingFaceJson && isFrontFaceLoaded) {
      const editableObjectsJson = JSON.stringify(editableFaces[`${slideIndex}`].canvas.current?.toJSON().objects);
      setBeforeEditingFaceJson(editableObjectsJson);
    }
  }, [slideIndex, editableFaces, cardFacesLoaded]);

  return { handleIndexChange, buttonProps };
};
