import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { LoadingButton } from '@hallmark/web.core.buttons.loading-button';
import { SaveButton } from '@hallmark/web.core.buttons.save-button';
import { BrandColors } from '@hallmark/web.styles.colors';
import { CEIconNames, IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useAnalyticsContext } from '../../context/analytics-context';
import { useAppContext, setSignInDialogOpen } from '../../context/app-context';
import { useCardContext } from '../../context/card-context';
import { useInitializationDataContext } from '../../context/data-context';
import { useProjectSummary, useSaveProject } from '../../hooks';
import { config } from '../../regional-config';
import { isLoggedIn } from '../../utils';
import { PodSaveCard } from '../../utils/analytics/analytics-types';
import { pushPodSaveCard } from '../../utils/analytics/analytics-utils';
import { useSaveProjectLogic } from './hooks/useSaveProjectLogic';
import styles from './save-project-link.module.scss';

export const SaveProjectLink = () => {
  const { trackSaveLink } = useAnalyticsContext();
  const { appState, appDispatch } = useAppContext();
  const { cardState } = useCardContext();
  const { productQuantity, isSaving } = appState;
  const { pathname } = useLocation();
  const hasUpdatedDraftName = useRef(false);
  const { t } = useTranslation();
  const { saveDraftName, saveProject } = useSaveProject();
  const { hasDefaultProjectName } = useProjectSummary();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const isEditorView = pathname.includes('edit');

  const handleSaveLogic = useSaveProjectLogic({
    hasUpdatedDraftName,
    hasDefaultProjectName,
    saveDraftName,
    saveProject,
    initializedData,
    productQuantity,
    isEditorView,
  });

  const handleClick = async () => {
    trackSaveLink();

    const saveCardEventData: Omit<PodSaveCard, 'event_id'> = {
      event: 'pod_save_card',
      item_id: cardState?.projectId,
      item_name: cardState?.cardTemplateName,
      card_template_id: cardState?.cardTemplateId,
    };
    pushPodSaveCard(saveCardEventData);

    if (!isLoggedIn() && config.saveProjects.hasSignInDialog) {
      setSignInDialogOpen(appDispatch, true);
    } else {
      handleSaveLogic();
    }
  };

  const renderSaveButton = () => {
    const buttonConfig = config.saveProjects.saveButton;

    if (buttonConfig.type === 'SaveButton') {
      return <SaveButton isSaving={isSaving} onClick={handleClick} iconName={CEIconNames.Save} />;
    } else {
      return (
        <LoadingButton
          testId="save-project-loading-button"
          addClass={styles['save-project-link']}
          click={handleClick}
          isLoading={isSaving}
          loadingColor={BrandColors.Disabled}
          defaultColor={BrandColors.Blue}
          loadingIconName={IconNames.SyncSyncInactive}
          isIconSpinning={true}
          loadingText={t('header.saving')}
          defaultText={t('header.save')}
        />
      );
    }
  };

  return renderSaveButton();
};
