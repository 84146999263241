import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { removeEditableAreas } from '../components/card-editor/utils/remove-editable-areas';
import {
  hideLoadingScreen,
  setIsSystemErrorOpen,
  setIsToasterOpen,
  setPersonalizationData,
  showLoadingScreen,
  useAppContext,
} from '../context/app-context';
import { useCardContext } from '../context/card-context';
import { updateProjectName, useInitializationDataContext } from '../context/data-context';
import { getPreview, savePersonalization } from '../services';
import { updateDraftName } from '../services/customization';
import { getTransformedPersonalizationData, validateImages } from '../utils';
import { copyCanvasObjects, restoreCanvasObjects } from '../utils/save-project';
import { didBackgroundImagesPopulate } from '../utils/utility';
import { useSystemErrorHandling } from './useSystemErrorHandling';

type SaveProjectOptions = { showLoadingScreen: boolean; shouldRestoreCanvas: boolean; generateFrontPreview: boolean };
/**
 * Custom hook used to save a project's draft name and personalization.
 * After the project is saved, the card canvas gets restored to its original state.
 * @returns callbacks to save the current project and update draft name
 */
export const useSaveProject = () => {
  const {
    initializedDataState: { data: initializedData },
    initializationDataDispatch,
  } = useInitializationDataContext();
  const { cardState } = useCardContext();
  const {
    appState: { productQuantity },
    appDispatch,
  } = useAppContext();
  const [onSystemError] = useSystemErrorHandling();
  const { t } = useTranslation();

  const missingBackgroundImageError = t('editorView.missingBackgroundImageError');

  const saveProject = useCallback(
    (
      options: SaveProjectOptions = {
        shouldRestoreCanvas: false,
        showLoadingScreen: true,
        generateFrontPreview: false,
      },
    ) => {
      const projectTypeCode = initializedData?.project_type_code;
      if (!projectTypeCode) {
        return;
      }

      const cardStateCopy = Object.assign({}, cardState);
      const canvasObjectsCopy = copyCanvasObjects(projectTypeCode, cardStateCopy.cardFacesList);

      // Clean canvas for /save-personalization call
      removeEditableAreas(cardStateCopy.cardFacesList);
      const saveData = getTransformedPersonalizationData(cardState, projectTypeCode);
      if (options.showLoadingScreen) {
        showLoadingScreen(appDispatch, 'Saving your greeting...');
      }
      if (options.shouldRestoreCanvas) {
        restoreCanvasObjects(cardStateCopy.cardFacesList, canvasObjectsCopy);
      }
      // confirm all card faces have valid background images
      const faces = initializedData?.variables?.template_data?.Faces;
      validateImages(faces, onSystemError, missingBackgroundImageError);
      if (!didBackgroundImagesPopulate(saveData)) {
        // If we have an invalid background image SRC in save-personalization data, display system error and don't save
        options.showLoadingScreen && hideLoadingScreen(appDispatch);
        setIsSystemErrorOpen(appDispatch, true);
        return;
      }

      return savePersonalization(saveData, initializedData?.project_id as string)
        .then(() => {
          setPersonalizationData(appDispatch, saveData);
        })
        .then(() => {
          if (!options.generateFrontPreview) {
            return;
          }
          const projectId = initializedData.project_id;
          const frontFaceIndex = cardState.cardFacesList.findIndex((face) => face.type === 'front');
          const frontFace = saveData[+frontFaceIndex];
          if (frontFace) {
            const { objects, version, backgroundImage } = frontFace.PrintJson;
            return getPreview(projectId, {
              save_preview_image: true,
              asset_type_code: 'P',
              personalization_data_json: {
                objects,
                version,
                backgroundImage,
              },
            });
          }
        })
        .catch((error) => {
          setIsToasterOpen(appDispatch, {
            title: t('saveProject.title'),
            children: t('saveProject.description'),
            variant: ToastVariants.Error,
          });

          onSystemError(error);
        })
        .finally(() => options.showLoadingScreen && hideLoadingScreen(appDispatch));
    },
    [
      initializedData?.project_type_code,
      initializedData?.project_id,
      initializedData?.variables?.template_data?.Faces,
      cardState,
      onSystemError,
    ],
  );

  /**
   * Update a project's name
   * @param draftName the new name for the project
   * @param isDraft
   */
  const saveDraftName = useCallback(
    (draftName: string, isDraft: boolean) => {
      const projectId = initializedData?.project_id;
      if (!projectId) {
        return;
      }

      return updateDraftName(draftName, projectId, productQuantity, isDraft)
        .then(() => updateProjectName(initializationDataDispatch, draftName))
        .catch((error) => onSystemError(error));
    },
    [initializedData?.project_id, onSystemError],
  );

  return { saveProject, saveDraftName };
};
