import React, { Reducer, createContext, useReducer, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { fabric } from 'fabric';
import { useCardContext } from '../../context/card-context';
import { CustomFabricObject } from '../../global-types/canvas';
import { CardType } from '../../global-types/card';
import { useActiveCanvas, useIsPodProductCode } from '../../hooks';
import { config } from '../../regional-config';
import { cropAndRotateImage } from '../../services/image';
import { getObjectByName, setAngle, hideMiddleControls, getCardFaceClipPath, fillPhotoZone } from '../../utils';
import { useAnalyticsContext } from '../analytics-context';
import { hideLoadingScreen, setIsImageEditDrawerOpen, showLoadingScreen, useAppContext } from '../app-context';
import { ImageEditDrawerModes } from '../app-context/app-context-types';
import { useInitializationDataContext } from '../data-context';
import { initState } from './crop-context-state';
import { CropContextState, CropAction, CropProviderProps, CreateContextProps } from './crop-context-types';

const CropContext = createContext<CreateContextProps>(undefined);

const cropReducer: Reducer<CropContextState, CropAction> = (state, action) => {
  const { type } = action || { type: null };
  switch (type) {
    default: {
      throw new Error(`Unhandled action type in card-context`);
    }
  }
};

export const CropContextProvider = ({ children }: CropProviderProps) => {
  const [cropState, cropDispatch] = useReducer(cropReducer, initState);
  const { appDispatch } = useAppContext();
  const { cardState } = useCardContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const isPodProductCode = useIsPodProductCode();
  const { updateEditFormats } = useAnalyticsContext();
  const [isCropping, setIsCropping] = useState(false);
  const [currentImage, setCurrentImage] = useState<CustomFabricObject | null>(null);
  const croppingArea = useRef<fabric.Rect>();
  const canvas = useActiveCanvas() as { current: fabric.Canvas };
  const { cardFacesList } = cardState;
  const currentCardFace = cardState.cardFacesList[`${cardState.activeCardIndex}`];

  const cropDeselectionHandler = useCallback(() => {
    // Maintain cropping rectangle selected until cropping is finished
    if (isCropping) {
      const croppingRect = croppingArea.current;
      if (croppingRect) {
        canvas.current.setActiveObject(croppingRect).renderAll();
      }
    }
  }, [isCropping]);

  /**
   * Restores the selectable and envented values of the canvas objects as they are disabled during cropping
   */
  const restoreSelectableObjects = () => {
    const canvasObjects = canvas.current.getObjects();
    canvasObjects.forEach((obj) => {
      if (obj.data?.isSelectable) {
        obj.selectable = true;
      }
      if (obj.data?.isEvented) {
        obj.evented = true;
      }
    });
  };

  const startCropping = () => {
    const currentImage = canvas?.current?.getActiveObject() as CustomFabricObject;
    if (currentImage) {
      if (currentImage.clipPath) {
        currentImage.clipPath = undefined;
      }
      setCurrentImage(currentImage);
      setIsCropping(true);
      currentImage.padding = 0;
    }
  };

  const finishCropping = async () => {
    canvas.current?.off('selection:cleared', cropDeselectionHandler);

    if (croppingArea.current && currentImage && currentImage.top !== undefined && currentImage.left !== undefined) {
      const currentAngle = croppingArea.current?.angle ?? 0;
      const angle = fabric.util.degreesToRadians(Math.abs((croppingArea.current.angle as number) - 360));
      const currentRotation = Math.abs(currentAngle - 360);
      const totalRotation = currentRotation + (currentImage.angle ?? 0);
      (croppingArea.current as CustomFabricObject)._setOriginToCenter();
      const newCoords = fabric.util.rotatePoint(
        croppingArea.current.getCenterPoint(),
        currentImage.getCenterPoint(),
        angle,
      );
      croppingArea.current.set({ left: newCoords.x, top: newCoords.y, angle: 0 }).setCoords();
      (croppingArea.current as CustomFabricObject)._resetOrigin();
      setAngle(currentImage, totalRotation);

      const imageCoords = Object.values(currentImage.aCoords ?? {});
      const minTopCoord = Math.min(...imageCoords.map((x) => x.y));
      const minLeftCoord = Math.min(...imageCoords.map((x) => x.x));
      const xPos = (croppingArea.current.left as number) - minLeftCoord;
      const yPos = (croppingArea.current.top as number) - minTopCoord;
      updateEditFormats({ crop: `x:${xPos}, y:${yPos}` });
      const imageProps = {
        x_pos: xPos / (currentImage.scaleX as number),
        y_pos: yPos / (currentImage.scaleY as number),
        width: croppingArea.current.getScaledWidth() / (currentImage.scaleX as number),
        height: croppingArea.current.getScaledHeight() / (currentImage.scaleY as number),
        rotation: totalRotation,
        source_version_id: currentImage.data?.version_id ?? '',
      };

      showLoadingScreen(appDispatch, 'Cropping your image...');

      // Restore objects selectable and evented original values
      restoreSelectableObjects();

      const response = await cropAndRotateImage(
        initializedData?.project_id as string,
        currentImage.name as string,
        imageProps,
      );
      return new Promise((resolve) => {
        fabric.Image.fromURL(
          response.data?.image_url as string,
          (img) => {
            config?.customControls?.image?.hideMiddleControls && hideMiddleControls(img);
            const cardFaceClipPath = getCardFaceClipPath(currentCardFace, 0);
            if (cardFaceClipPath) {
              // Ensure image respects copyrighted zone boundaries
              img.clipPath = cardFaceClipPath;
            }
            // pod and photozone image
            if (isPodProductCode && currentImage.data?.photoZoneId) {
              img.data = {
                ...currentImage.data,
                version_id: response.data?.version_id ?? '',
              };

              img.name = currentImage.name;
              canvas.current.remove(currentImage);
              canvas.current.remove(croppingArea.current as fabric.Object);
              croppingArea.current = undefined;
              fillPhotoZone(currentImage.data.photoZoneId, img, cardFacesList);
              setAngle(img, currentAngle);
              setCurrentImage(null);
              setIsCropping(false);
              hideLoadingScreen(appDispatch);
              return resolve(img);
            }

            const photoZone = getObjectByName(currentImage.data?.zoneName, canvas.current);
            // if we have a zone we want to make sure we scale the image into the zone size
            if (photoZone) {
              img.scaleToWidth(photoZone.getScaledWidth());
              if (img.getScaledHeight() > photoZone.getScaledHeight()) {
                img.scaleToHeight(photoZone.getScaledHeight());
              }
            } else {
              img.scaleToWidth(croppingArea.current?.getScaledWidth() as number);
            }
            if (currentImage.data?.clipPathSettings && !currentImage?.data?.isPodHandwriting) {
              const clipPath = new fabric.Rect(currentImage.data.clipPathSettings);
              img.top = (clipPath.top as number) - img.getScaledHeight() / 2;
              img.left = (clipPath.left as number) - img.getScaledWidth() / 2;
              img.clipPath = clipPath;
            } else {
              img.top = croppingArea.current?.top;
              img.left = croppingArea.current?.left;
            }
            img.data = {
              ...currentImage.data,
              version_id: response.data?.version_id ?? '',
            };
            img.name = currentImage.name;

            img.onSelect = () => {
              setIsImageEditDrawerOpen(appDispatch, ImageEditDrawerModes.UserImage);
              return false;
            };
            canvas?.current?.add(img);
            canvas?.current?.setActiveObject(img);
            setAngle(img, currentAngle);
            // Remove cropping rectangle
            canvas.current.remove(croppingArea.current as fabric.Object);
            croppingArea.current = undefined;
            canvas.current.remove(currentImage);
            setCurrentImage(null);
            setIsCropping(false);
            hideLoadingScreen(appDispatch);
            resolve(img);
            img.set({
              left: newCoords.x,
              top: newCoords.y,
              opacity: 1,
              padding: 10,
              angle: currentAngle,
            });
          },
          { crossOrigin: 'anonymous' },
        );
      });
    }
  };

  const createCroppingArea = () => {
    if (!currentImage) {
      return;
    }
    currentImage?.fire('custom:hideBorders');

    const { height, width, left, top, angle, scaleX, scaleY } = currentImage;
    if (left !== undefined && top !== undefined) {
      const zoneSize = {
        width: (width as number) * (scaleX as number),
        height: (height as number) * (scaleY as number),
      };

      const croppingRect = new fabric.Rect({
        fill: '',
        originX: 'left',
        originY: 'top',
        width: zoneSize.width - 1,
        height: zoneSize.height - 1,
        visible: true,
        opacity: 1,
        left,
        top,
        angle,
        borderColor: '#ED3E6C',
        borderDashArray: [5, 7],
        borderOpacityWhenMoving: 1,
        hasBorders: true,
        name: 'cropping-rect',
        padding: 8,
      });

      const clipPath = new fabric.Rect({
        width: zoneSize.width,
        height: zoneSize.height,
        left,
        top,
        absolutePositioned: true,
        angle,
      });

      croppingRect.clipPath = clipPath;

      currentImage.opacity = 1;
      // Make other objects unselectable/uneventable until cropping is finished, keep track of original values
      const canvasObjects = canvas.current.getObjects();
      canvasObjects.forEach((obj) => {
        if (obj.selectable === true) {
          obj.selectable = false;
          obj.data.isSelectable = true;
        }
        if (obj.evented === true) {
          obj.evented = false;
          obj.data.isEvented = true;
        }
      });

      canvas.current.add(croppingRect);
      canvas.current.setActiveObject(croppingRect);
      croppingRect.bringToFront();

      canvas.current.on('selection:cleared', cropDeselectionHandler);

      croppingArea.current = croppingRect;
      if (initializedData?.project_type_code === CardType.SAS) {
        croppingArea.current.setControlsVisibility({ mtr: false });
        croppingArea.current.set({ cornerSize: 28 });
      }
    }
  };

  const cancelCropping = () => {
    canvas.current?.off('selection:cleared', cropDeselectionHandler);
    if (croppingArea.current && currentImage) {
      canvas.current.remove(croppingArea.current);
      canvas.current.setActiveObject(currentImage);
      setCurrentImage(null);
      setIsCropping(false);
      restoreSelectableObjects();
      canvas.current.requestRenderAll();
      currentImage.padding = 10;
    }
  };

  useEffect(() => {
    if (isCropping) {
      createCroppingArea();
    }
  }, [isCropping]);

  return (
    <CropContext.Provider
      value={{
        cropDispatch,
        cropState,
        startCropping,
        finishCropping,
        isCropping,
        cancelCropping,
      }}
    >
      {children}
    </CropContext.Provider>
  );
};

// Hooks
export const useCropContext = () => {
  const context = useContext(CropContext);
  if (context === undefined) {
    throw new Error('useCropContext must be used within CropProvider');
  }

  return context;
};
