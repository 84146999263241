import { RefObject } from 'react';
import { fabric } from 'fabric';
import { CanvasDataTypes, CustomFabricType } from '../canvas';

export const createFabricImageInstance = (
  imageRef: RefObject<HTMLImageElement>,
  canvasDataType: CanvasDataTypes.UserImage | CanvasDataTypes.StickerImage,
  imageName: string | undefined,
  borderColor: string,
  currentCanvas: fabric.Canvas,
  customType?: CustomFabricType,
): fabric.Image => {
  const canvasWidth = currentCanvas.getWidth();
  const canvasHeight = currentCanvas.getHeight();

  const imageElement = imageRef.current as HTMLImageElement;

  const imageWidth = imageElement.width;
  const imageHeight = imageElement.height;

  const leftPosition = canvasWidth + imageWidth - 20;

  const topPosition = canvasHeight - imageHeight + 20;

  const fabricImage = new fabric.Image(imageElement, {
    left: leftPosition,
    top: topPosition,
    name: imageName,
    data: {
      type: canvasDataType,
      customType: customType,
    },
    padding: 10,
    crossOrigin: 'anonymous',
    borderColor,
  });

  return fabricImage;
};
