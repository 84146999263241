import API from '@ce-digital/api-middleware';
import axios from 'axios';
import {
  getImagesResponse,
  linkImageResponse,
  uploadImageResponse,
  cleanUpImageResponse,
  cropAndRotateResponse,
  deletePhotoTrayImageResponse,
  deleteImageResponse,
} from '../data';
import {
  ImageResponse,
  LinkPhotoResponse,
  CleanUpImageResponse,
  CropAndRotateResponse,
  linkPhotoFormData,
  cleanUpFormData,
  cropAndRotateFormData,
} from '../global-types';
import { ApiResponse } from '../global-types/services';
import { getCookie } from '../utils';
import { processResponse } from './middleware';

const PODService = new API();
// PODService.updateDefaultConfig('withCredentials', true);

const mockResponse = process.env.REACT_APP_MOCK_INITIALIZATION_SERVICE === 'true';

const accountId = getCookie('accountId');

export const linkPhoto = (formData: linkPhotoFormData, project_id: string): Promise<ApiResponse<LinkPhotoResponse>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<LinkPhotoResponse> = linkImageResponse;
      resolve(response);
    }

    const response = PODService.post('/customization', `/${accountId}/${project_id}/images`, formData);
    processResponse(response, resolve, reject);
  });
};

export const uploadPhoto = (formData: FormData): Promise<ApiResponse<ImageResponse>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<ImageResponse> = uploadImageResponse;
      resolve(response);
    }

    const response = PODService.post('/customization', `/${accountId}/photo-tray-images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    processResponse(response, resolve, reject);
  });
};

export const uploadPhotoS3 = async (
  formData: FormData,
  projectId: string,
  isHandwritingImage: boolean,
): Promise<ApiResponse<ImageResponse>> => {
  const file = formData.get('file') as File;
  const [, format] = file.type.split('/');

  const responseURLS = await PODService.post('/customization', `/${accountId}/asset-storage-url`, {
    count: 1,
    format,
    location: 'images',
  });

  const urlS3 = responseURLS.data[0];

  await axios.put(urlS3, file);

  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<ImageResponse> = uploadImageResponse;
      resolve(response);
    }

    const [image_url] = urlS3.split('?');
    const response = PODService.post(
      '/customization',
      `/${accountId}/${projectId}/images/link`,
      {
        image_url,
        display_indicator: true,
        is_handwriting_image: isHandwritingImage,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    processResponse(response, resolve, reject);
  });
};

export const getPhotos = (): Promise<ApiResponse<ImageResponse[]>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<ImageResponse[]> = getImagesResponse;
      resolve(response);
    }

    const response = PODService.get('/customization', `/${accountId}/photo-tray-images`);
    processResponse(response, resolve, reject);
  });
};

export const cleanUpImage = (
  projectId: string,
  imageId: string,
  formData: cleanUpFormData,
): Promise<ApiResponse<CleanUpImageResponse>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<CleanUpImageResponse> = cleanUpImageResponse;
      resolve(response);
    }

    const response = PODService.post(
      '/customization',
      `/${accountId}/${projectId}/images/${imageId}/cleanup`,
      formData,
    );
    processResponse(response, resolve, reject);
  });
};

export const deleteImage = (projectId: string, imageId: string): Promise<ApiResponse<null>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(deleteImageResponse);
    }

    const response = PODService.delete('/customization', `/${accountId}/${projectId}/images/${imageId}`);
    processResponse(response, resolve, reject);
  });
};

export const deletePhotoTrayImage = (photoTrayImageId: string) => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(deletePhotoTrayImageResponse);
    }
    const response = PODService.delete('/customization', `/${accountId}/photo-tray-images/${photoTrayImageId}`);
    processResponse(response, resolve, reject);
  });
};

export const cropAndRotateImage = (
  projectId: string,
  imageId: string,
  formData: cropAndRotateFormData,
): Promise<ApiResponse<CropAndRotateResponse>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<CropAndRotateResponse> = cropAndRotateResponse;
      resolve(response);
    }

    const response = PODService.post(
      '/customization',
      `/${accountId}/${projectId}/images/${imageId}/crop-and-rotate`,
      formData,
    );
    processResponse(response, resolve, reject);
  });
};
