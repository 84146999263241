// images mock data will go here
export const getImagesResponse = {
  meta: {
    code: 200,
    version: '11f0e081',
  },
  data: [
    {
      photo_tray_image_id: '3b95feaf-75cb-4d85-ac41-cced74f86261',
      account_id: '2125215824',
      width: 702,
      height: 318,
      image_url:
        'https://s3.us-west-2.amazonaws.com/hmklabs-dotcom-dev-us-west-2-consumer-images/images/e32a331a-69bc-4b29-9b55-c3ef88ba27241687184559264975024.png',
      is_handwriting_image: false,
      created_at: '2022-10-11T22:44:05Z',
      last_updated_at: '2022-10-11T22:44:05Z',
    },
    {
      photo_tray_image_id: 'c8cd3568-a3bc-4595-861b-4bb4e98d265d',
      account_id: '2125215824',
      width: 1060,
      height: 578,
      image_url:
        'https://s3.us-west-2.amazonaws.com/hmklabs-dotcom-dev-us-west-2-consumer-images/images/bd7cf6f6-6e8f-48a0-bcc8-ab4e01d5f4ec3950896730125624717.png',
      is_handwriting_image: false,
      created_at: '2022-10-11T22:49:24Z',
      last_updated_at: '2022-10-11T22:49:24Z',
    },
    {
      photo_tray_image_id: 'c34d405b-6a33-4a15-b94f-285be6c9b091',
      account_id: '2125215824',
      width: 1060,
      height: 578,
      image_url:
        'https://s3.us-west-2.amazonaws.com/hmklabs-dotcom-dev-us-west-2-consumer-images/images/00b04183-c712-41f8-9f1e-59842b5035e28273290538659802269.png',
      is_handwriting_image: false,
      created_at: '2022-10-12T00:30:08Z',
      last_updated_at: '2022-10-12T00:30:08Z',
    },
    {
      photo_tray_image_id: '6e17dac1-c58f-4f4e-b506-2a3451172370',
      account_id: '2125215824',
      width: 2934,
      height: 1866,
      image_url:
        'https://s3.us-west-2.amazonaws.com/hmklabs-dotcom-dev-us-west-2-consumer-images/images/0e4375f5-3a76-4e0d-9986-aa049553ed626296367092202729479.png',
      is_handwriting_image: false,
      created_at: '2022-10-12T01:08:02Z',
      last_updated_at: '2022-10-12T01:08:02Z',
    },
    {
      photo_tray_image_id: '02a0ca5d-8ee8-44ee-9f31-7ee4d2ea4b41',
      account_id: '2125215824',
      width: 702,
      height: 318,
      image_url:
        'https://s3.us-west-2.amazonaws.com/hmklabs-dotcom-dev-us-west-2-consumer-images/images/bef9c52e-5639-4c72-ba6a-a0e3183a65879029029644282286269.png',
      is_handwriting_image: false,
      created_at: '2022-10-12T01:18:08Z',
      last_updated_at: '2022-10-12T01:18:08Z',
    },
  ],
};

export const uploadImageResponse = {
  meta: {
    code: 201,
    version: '11f0e081',
  },
  data: {
    photo_tray_image_id: '93008187-d1d9-45fb-ab1c-9d4c3e502cb1',
    account_id: '2125242962',
    width: 1060,
    height: 578,
    image_url:
      'https://s3.us-west-2.amazonaws.com/hmklabs-dotcom-dev-us-west-2-consumer-images/images/52861915-c5c9-4a61-9010-028ac5a3d8018505906760983331750.png',
    is_handwriting_image: false,
    created_at: '2022-10-12T12:50:25.380702596Z',
    last_updated_at: '2022-10-12T12:50:25.380717969Z',
  },
};

export const linkImageResponse = {
  meta: {
    code: 201,
    version: '11f0e081',
  },
  data: {
    image_id: 'fa3852be-648a-476e-93e9-8850e4b559fa',
    image_reference_id: '936ae156-5a34-4d16-b7ed-c99a9f057765',
    image_url:
      'https://s3.amazonaws.com/hmklabs-dotcom-dev-us-west-2-consumer-images/images/901b73f2-965b-4064-8136-3cfc8e696c895577006791947779410.png',
    created_at: '2022-10-12T12:51:14.13540363Z',
    last_updated_at: '2022-10-12T12:51:14.135404518Z',
  },
};

export const cleanUpImageResponse = {
  meta: {
    code: 201,
    version: '2.2.0',
  },
  data: {
    version_id: '6d977863-b7dd-42c9-86cb-38687dd8b910',
    source_version_id: '',
    version_type_code: 'C',
    image_url:
      'https://s3.amazonaws.com/hmklabs-dotcom-dev-us-west-2-consumer-images/images/bd5c6ecf-7585-4dd6-92d1-fea518cddb5c05577006791947779410.png',
    attributes: null,
    created_at: '2021-04-10T03:18:07.6929123Z',
    last_updated_at: '2021-04-10T03:18:07.6929235Z',
  },
};

export const deleteImageResponse = {
  meta: {
    code: 204,
    version: '2.2.0',
  },
  data: null,
};

export const cropAndRotateResponse = {
  meta: { code: 201, version: '180a49e5' },
  data: {
    version_id: '6d977863-b7dd-42c9-86cb-38687dd8b910',
    source_version_id: '',
    version_type_code: 'C',
    image_url:
      'https://s3.amazonaws.com/hmklabs-dotcom-dev-us-west-2-consumer-images/images/bd5c6ecf-7585-4dd6-92d1-fea518cddb5c05577006791947779410.png',
    attributes: {
      height: 779,
      rotation: 0,
      width: 949,
      x_pos: 0,
      y_pos: 0,
    },
    created_at: '2021-04-10T03:18:07.6929123Z',
    last_updated_at: '2021-04-10T03:18:07.6929235Z',
  },
};

export const deletePhotoTrayImageResponse = {
  meta: { code: 201, version: '180a49e5' },
  data: {
    photo_tray_image_id: '47c20ef6-c859-41d2-8818-48b18a46d4aa',
    account_id: '2125374326',
    width: 800,
    height: 550,
    image_url:
      'https://s3.us-west-2.amazonaws.com/hmklabs-dotcom-dev-us-west-2-consumer-images/images/c3fcc52a-451f-4283-9d2b-325ce795c0c14419084506373641220.jpeg',
    is_handwriting_image: false,
    created_at: '2022-12-21T15:32:28.253326802Z',
    last_updated_at: '2022-12-21T15:32:28.253328014Z',
  },
};
