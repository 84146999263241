import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { DialogCE, DialogCEContentType, DialogCEFooterType } from '@hallmark/web.core.feedback.dialog-ce';
import { TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useQueryParams } from '../../hooks';
import { config } from '../../regional-config';
import styles from './preview-dialog.module.scss';

type cancelButtonProps = {
  click: () => void;
  text: string;
};

type actionButtonProps = {
  click: () => void;
};

export const ActionButton = ({ click }: actionButtonProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Button
      click={click}
      mode={config?.dialog?.applyButtonMode || ButtonModes.Primary}
      typographyVariant={config?.dialog?.applyTypographyVariant || TypographyVariants.Body}
      testId="action-button"
      addClass={styles['primary-button']}
    >
      {t('previewView.actionButton')}
    </Button>
  );
};

export const CancelButton = ({ click, text }: cancelButtonProps): ReactElement => {
  return (
    <Button
      click={click}
      testId="cancel-button"
      addClass={styles['edit-button']}
      mode={config?.dialog?.cancelButtonMode || ButtonModes.TextLink}
      typographyVariant={config?.dialog?.cancelTypographyVariant || TypographyVariants.Body}
    >
      {text}
    </Button>
  );
};

type PreviewDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (quantity: string) => void;
};

export const PreviewDialog = ({ isOpen, onClose, handleSubmit }: PreviewDialogProps) => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const quantity = queryParams.get('qty') ?? '1';
  const { pathname } = useLocation();
  const isPreviewPage = pathname.includes('/preview');

  return (
    <DialogCE
      id={'add-to-cart-dialog'}
      isOpen={isOpen}
      onClose={onClose}
      hasGrayBackground={true}
      type={DialogCEContentType.Modal}
      title={`${t('previewView.confirmationTitle')}`}
      footerType={DialogCEFooterType.Cancel}
      disableDialogScroll={true}
      actionButton={<ActionButton click={() => handleSubmit(quantity)} />}
      cancelButton={
        <CancelButton
          click={onClose}
          text={isPreviewPage ? t('previewView.previewButton') : t('editorView.previewButton')}
        />
      }
    >
      <p>{`${isPreviewPage ? t('previewView.confirmationDescription') : t('editorView.confirmationDescription')}`} </p>
    </DialogCE>
  );
};
