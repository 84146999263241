import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useCameraAccessControl = () => {
  const { search } = useLocation();
  const [allowCamera, setAllowCamera] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const cameraDisabled = queryParams.get('camera_disabled') === 'true';
    setAllowCamera(!cameraDisabled);
  }, [search]);

  return allowCamera;
};
