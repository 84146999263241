import { useLocation } from 'react-router-dom';
import { useInitializationDataContext } from '../context/data-context';
import { RegionalCodesList } from '../global-types';
import { CardType } from '../global-types/card';
import { EnvironmentsType } from '../global-types/feature-flags';
import { useIsOneToMany } from './useIsOneToMany';

export const useFeatureFlags = () => {
  const {
    initializedDataState: { data },
  } = useInitializationDataContext();
  const isOneToMany = useIsOneToMany();
  const { pathname } = useLocation();

  const enabledEnvironments = (environments: EnvironmentsType[]): boolean => {
    const currentEnv = process.env.REACT_APP_CURRENT_ENV ?? null;
    const currentTheme = process.env.REACT_APP_THEME ?? null;

    if (!currentEnv || !currentTheme) {
      return false;
    }
    const currentEnvCode = `${currentTheme}-${currentEnv}`;
    // Checks for current environment in list of environments
    const matchedEnvironment = environments.filter((environment) => environment === currentEnvCode);
    // If no match is found
    if (matchedEnvironment.length <= 0) {
      return false;
    }
    // Get regional code of matched environment (for example, 'us' from 'us-dev');
    const regionalCode = matchedEnvironment[0].split('-')[0];

    if (regionalCode) {
      const regionsList = {
        us: regionalCode === 'us',
        uk: regionalCode === 'uk',
        ce: regionalCode === 'ce',
        all: regionalCode === 'all',
      };
      return regionsList[regionalCode as RegionalCodesList] || false;
    } else {
      return false;
    }
  };

  const enabledRoutes = (routes: string[]): boolean => {
    if (!pathname) return false;

    const matchedRoute = routes.filter((route) => pathname.includes(route));

    if (matchedRoute.length <= 0) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Flags
   */

  // Should only show in US dev
  const DISPLAY_ZOOM_DEV = enabledEnvironments([EnvironmentsType.UsDev]);

  // Should only show in US dev and stage
  const SAVED_PROJECTS = enabledEnvironments([
    EnvironmentsType.CeDev,
    EnvironmentsType.UsDev,
    EnvironmentsType.UsStage,
    EnvironmentsType.UsProd,
    EnvironmentsType.UkDev,
    EnvironmentsType.UkStage,
  ]);

  /** Enable hasControls when adding editable texts if the DYNAMIC_TEXBOXES feature is enabled UsDev, UsStage */
  const DYNAMIC_TEXBOXES = enabledEnvironments([EnvironmentsType.UsDev, EnvironmentsType.UkDev]);

  const SAVED_PROJECTS_LINK = enabledRoutes(['/edit/']) && SAVED_PROJECTS;

  // Should show on US dev and stage, where project_type_code is digital greeting
  const DIGITAL_GREETINGS =
    enabledEnvironments([EnvironmentsType.UsDev, EnvironmentsType.UsStage]) &&
    (data?.project_type_code === CardType.DG || data?.is_digital_fulfillment === true);

  // Should show on dev uk
  const POD_WRITE_A_MESSAGE = enabledEnvironments([EnvironmentsType.UkDev]);

  // should show on dev US
  const DYNAMIC_TEXBOX_DRAWER = enabledEnvironments([EnvironmentsType.UsDev]);

  // feature for hiding and showing the toolbar based on if a S&S user zome is selected or not (MIMIR-3137)
  const SAS_TOOLBAR_TOGGLE = enabledEnvironments([EnvironmentsType.UsDev]);

  // Only show in US dev and stage, with POD one to many cards.
  const ENVELOPE_SELECTION =
    enabledEnvironments([EnvironmentsType.UsDev, EnvironmentsType.UsStage, EnvironmentsType.UsProd]) &&
    isOneToMany &&
    data?.project_type_code === CardType.POD;

  const INSIDE_PLACEHOLDER_TEXT = enabledEnvironments([
    EnvironmentsType.UkDev,
    EnvironmentsType.UkStage,
    EnvironmentsType.UkProd,
  ]);

  // Enables and disables the direct-to-s3 image upload endpoint
  const S3_PHOTO_UPLOAD = enabledEnvironments([EnvironmentsType.UsDev]);

  return {
    SAVED_PROJECTS,
    ENVELOPE_SELECTION,
    S3_PHOTO_UPLOAD,
    DIGITAL_GREETINGS,
    POD_WRITE_A_MESSAGE,
    SAVED_PROJECTS_LINK,
    INSIDE_PLACEHOLDER_TEXT,
    DYNAMIC_TEXBOXES,
    DYNAMIC_TEXBOX_DRAWER,
    DISPLAY_ZOOM_DEV,
    SAS_TOOLBAR_TOGGLE,
  };
};
