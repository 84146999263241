import React, { ReactElement, useState, useEffect, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import {
  IconButton,
  IconButtonBrandColors,
  IconButtonVariants,
  IconNames,
} from '@hallmark/web.core.buttons.icon-button';
import { Image } from '@hallmark/web.core.display.image';
import { Dialog } from '@hallmark/web.core.feedback.dialog';
import { DialogIconNames, DialogContentType, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { Carousel } from '@hallmark/web.core.surfaces.carousel';
import { SEO } from '@hallmark/web.page-components.seo';
import { BrandColors } from '@hallmark/web.styles.colors';
import { AddToCart } from '../../components/add-to-cart';
import { CardSelectBar } from '../../components/card-select-bar';
import { Layout } from '../../components/layout';
import { CancelButton, PreviewDialog } from '../../components/preview-dialog/preview-dialog';
import { useAnalyticsContext } from '../../context/analytics-context';
import { hideLoadingScreen, showLoadingScreen, useAppContext } from '../../context/app-context';
import { setActiveCardIndex, useCardContext } from '../../context/card-context';
import {
  setBtlyUrl,
  setImages,
  setIsPreviewDialogOpen,
  setPreviewSlideIndex,
} from '../../context/card-context/card-context-actions';
import { useInitializationDataContext } from '../../context/data-context';
import {
  CardFacePreviewImage,
  EnvelopePreviewResponse,
  PreviewResponseData,
  ApiResponse,
  ErrorResponse,
  CardType,
  AssetFormData,
  RecipientResponseData,
  ProjectTypeCode,
  RegionalCodesList,
} from '../../global-types';
import { useBasket, useLineItemUUID, useSubmitOrder } from '../../hooks';
import { useEmailOrderProcess } from '../../hooks/useEmailOrderProcess';
import { useSystemErrorHandling } from '../../hooks/useSystemErrorHandling';
import { config } from '../../regional-config';
import { getPreview, getEnvelopePreview, saveRecipientAssets } from '../../services';
import { removeBeforeUnloadEvent, loadProjectDetails } from '../../utils';
import { ViewItem } from '../../utils/analytics/analytics-types';
import { pushViewItem } from '../../utils/analytics/analytics-utils';
import { isAnyOfRegions } from '../../utils/utility';
import { useEditorNavigation } from '../editor/hooks/useEditorNavigation';
import { envelopes } from '../envelope/envelope.data';
import { EmailSplash } from './fragments';
import styles from './preview.module.scss';
import { getEnvelopePreviewBody, handleCloseAndKeepShopping } from './utils';
import { getShouldSavePreviewImage } from './utils/getShouldSavePreviewImage';

/**
 * Lazy loading Animation preview since it's
 * only needed for DGs
 */
const AnimationPreviewComponent = lazy(() =>
  import('../../components/animation-preview').then((module) => ({
    default: module.AnimationPreview,
  })),
);

/**
 * Suspense fallback component for DG
 * AnimationPreviewComponent
 */
const AnimationPreviewLoader = () => <div>Loading</div>;

export const PreviewView = (): ReactElement => {
  const {
    initializedDataState: { data: initializedData, addressData },
  } = useInitializationDataContext();
  const { cardState, cardDispatch } = useCardContext();
  const { appState, appDispatch } = useAppContext();
  const {
    pageNavigationButtonClicked,
    trackLoadPreview,
    trackReturnToEdit,
    trackDGPlayAnimation,

    trackDGViewFacePreview,
  } = useAnalyticsContext();
  const { handleIndexChange } = useEditorNavigation();
  const { images, btlyUrl, previewSlideIndex, isPreviewDialogOpen } = cardState;
  const [isAnimationDialogOpen, setIsAnimationDialogOpen] = useState(false);

  const [animationPreviewImages, setAnimationPreviewImages] = useState<string[]>([]);

  const [onSystemError] = useSystemErrorHandling();
  // we pass a lot of the basket data to the useSubmitOrder hook, but have to initialize it here to share the state with the hook
  const { addToCart, basket, recentBasket, basketError, closeModalOverride, keepShoppingOverride } = useBasket();
  useLineItemUUID();

  const { t } = useTranslation();
  const projectId = initializedData?.project_id || '';
  const { initializedDataState } = useInitializationDataContext();
  const { isUS, isUK } = initializedDataState;
  const projectTypeCode = sessionStorage.getItem('lastProjectTypeCode');
  const isDigitalGreetings = projectTypeCode === CardType.DG;
  const { isMobileApp } = appState;
  const continueMessage = isDigitalGreetings ? t('previewView.sendEmailNow') : t('previewView.addToCart');
  const playAnimationMessage = isMobileApp ? t('previewView.playAnimationMobile') : t('previewView.playAnimation');

  const { showEmailConfirmation, sendEmailNow, isSendingEmail } = useEmailOrderProcess({ images, btlyUrl });

  const updateImages = (newImages: CardFacePreviewImage[]) => setImages(cardDispatch, newImages);
  const updateBtlyUrl = (newUrl: string) => setBtlyUrl(cardDispatch, newUrl);
  const updatePreviewSlideIndex = (index: number) => setPreviewSlideIndex(cardDispatch, index);
  const updateIsPreviewDialogOpen = (isOpen: boolean) => setIsPreviewDialogOpen(cardDispatch, isOpen);
  const { handleSubmit } = useSubmitOrder(images, updateIsPreviewDialogOpen, addToCart);

  const handlePlayAnimation = () => {
    loadProjectDetails(projectId).then((animationPanels) => {
      setAnimationPreviewImages([animationPanels.F.resize_urls.M, animationPanels.I.resize_urls.M]);

      setIsAnimationDialogOpen(true);
      trackDGPlayAnimation();
    });
  };

  const handleCloseAnimationDialog = () => {
    setIsAnimationDialogOpen(false);
  };

  const handleImageDotClick = (newIndex: number) => {
    updatePreviewSlideIndex(newIndex);
  };

  const setAnalyticsIndex = (index: number) => {
    updatePreviewSlideIndex(index);
    setActiveCardIndex(cardDispatch, index);
  };

  const isEnvelope = (index: number) => {
    // eslint-disable-next-line security/detect-object-injection
    return images[index].type === 'Envelope';
  };

  const handleNextButtonClick = (index: number) => {
    if (index < images.length - 1) {
      pageNavigationButtonClicked.current = 'button';
      setAnalyticsIndex(index + 1);
    } else {
      isDigitalGreetings ? sendEmailNow() : updateIsPreviewDialogOpen(true);
    }
  };

  const handlePrevClick = (index: number) => {
    if (index !== 0) {
      setAnalyticsIndex(index - 1);
    }
  };

  const handleChangeSlide = (newIndex: number) => {
    trackDGViewFacePreview(isEnvelope(newIndex));
    if (newIndex !== previewSlideIndex) setAnalyticsIndex(newIndex);
  };

  const loadPreview = async () => {
    const projectId = initializedData?.project_id;
    const personalizationData = appState.personalizationData;
    if (!personalizationData || !projectId) {
      return;
    }
    const previewImages: CardFacePreviewImage[] = [];
    try {
      showLoadingScreen(appDispatch, '');
      for (let index = 0; index < appState.personalizationData.length; index++) {
        const showPreview = cardState.cardFacesList[+index]?.previewDisplayIndicator ?? false;
        if (!showPreview) {
          continue;
        }
        const cardFace = appState.personalizationData[+index];
        const { version, objects = [], backgroundImage } = cardFace.PrintJson ?? {};
        const { dimensions, type: cardType } = cardState.cardFacesList[+index];
        if (objects.length === 0 && !isDigitalGreetings) {
          previewImages.push({
            photoZoneTemplate: cardFace.ImagePreview,
            type: cardType.replace('-', ' '),
            dimensions: dimensions,
            faceNumber: cardFace.FaceNumber,
          });
        } else {
          const shouldSavePreviewImage = isAnyOfRegions([RegionalCodesList.ce])
            ? true
            : getShouldSavePreviewImage(cardType, projectTypeCode, isDigitalGreetings);
          const response: ApiResponse<PreviewResponseData> = await getPreview(projectId, {
            save_preview_image: shouldSavePreviewImage,
            ...(shouldSavePreviewImage && { asset_type_code: 'P' }),
            personalization_data_json: {
              version: version,
              objects: objects,
              backgroundImage: backgroundImage,
            },
          });

          previewImages.push({
            photoZoneTemplate: response.data?.encoded_image as string,
            type: cardType.replace('-', ' '),
            dimensions: dimensions,
            url: response.data?.image_url || '',
            faceNumber: cardFace.FaceNumber,
          });
        }
      }

      const frontFace = previewImages[0];
      const firstIndex = frontFace?.faceNumber ? frontFace.faceNumber - 1 : 0;
      setActiveCardIndex(cardDispatch, firstIndex);

      if (isUS && !isDigitalGreetings) {
        const { recipient, sender } = addressData;
        if (!recipient && !sender) {
          previewImages.push({
            photoZoneTemplate: envelopes[0].image,
            type: 'Envelope',
            dimensions: cardState.cardFacesList[0].dimensions,
          });
        } else {
          const envelopePreviewBody = getEnvelopePreviewBody(
            recipient,
            sender,
            initializedData.product.envelope_color,
            initializedData.variables.template_data.CardSize,
          );
          const envResponse: EnvelopePreviewResponse = await getEnvelopePreview(projectId, envelopePreviewBody);

          previewImages.push({
            photoZoneTemplate: envResponse.data?.encoded_image as string,
            type: 'Envelope',
            dimensions: cardState.cardFacesList[0].dimensions,
          });
        }
      }
      updateImages(previewImages);
    } catch (errors: any) {
      onSystemError(errors as ErrorResponse);
    } finally {
      hideLoadingScreen(appDispatch);
    }
  };

  const goBack = () => {
    trackReturnToEdit();
    removeBeforeUnloadEvent();
    window.location.pathname = `/card/customization/edit/${projectId}`;
    handleIndexChange(0, 0);
  };

  useEffect(() => {
    if (cardState) {
      const viewItemEventData: Omit<ViewItem, 'event_id'> = {
        event: 'view_item',
        journey_type: 'pod_editor',
      };
      pushViewItem(viewItemEventData);
    }
  }, [cardState]);

  useEffect(() => {
    if (projectId) {
      trackLoadPreview();
      loadPreview();
    }
  }, [projectId]);

  useEffect(() => {
    if (images.length >= 2 && isDigitalGreetings && images[0].url && images[1].url) {
      const fetchDigitalAssets = async (frontUrl: string, insideUrl: string) => {
        // Make a request to the "/assets" endpoint only once, passing both URLs in the body of the request
        const assetFormData: AssetFormData = {
          front_url: frontUrl,
          inside_url: insideUrl,
        };

        const responseRecipient: ApiResponse<RecipientResponseData> = await saveRecipientAssets(
          projectId,
          assetFormData,
        );

        updateBtlyUrl(responseRecipient.data?.url || '');
      };

      fetchDigitalAssets(images[0].url, images[1].url);
    }
  }, [images]);

  const isLastSlide = !(previewSlideIndex < images.length - 1);
  const isFirstSlide = previewSlideIndex === 0;

  const onEmailSplashContinue = () => {
    removeBeforeUnloadEvent();
    handleCloseAndKeepShopping(projectTypeCode as ProjectTypeCode);
  };

  const onEmailSplashPlayAnimations = () => {
    handlePlayAnimation();
  };

  const AnimationDialog = () => (
    <Dialog
      id={'play-animation-dialog'}
      isOpen={isAnimationDialogOpen}
      onClose={() => setIsAnimationDialogOpen(false)}
      closeButtonId={'close'}
      accentIcon={DialogIconNames.CardFeaturesBold}
      accentIconColor={BrandColors.White}
      hasGrayBackground={true}
      type={DialogContentType.FeatureModal}
      footerType={DialogFooterType.Passive}
      disableDialogScroll={true}
      cancelButton={<CancelButton click={handleCloseAnimationDialog} text={t('previewView.previewButton')} />}
      title={`${t('previewView.previewAnimationModalTitle')}`}
      locale={isUK ? 'uk' : 'us'}
    >
      <Suspense fallback={AnimationPreviewLoader()}>
        <AnimationPreviewComponent panelImages={animationPreviewImages} />
      </Suspense>
    </Dialog>
  );

  if (showEmailConfirmation) {
    return (
      <>
        <EmailSplash onContinue={onEmailSplashContinue} onPlayAnimations={onEmailSplashPlayAnimations} />
        <AnimationDialog />
      </>
    );
  }

  const playAnimationButton = (
    <Button
      testId="play-animation-button"
      addClass={styles['animation-button-mobile']}
      click={handlePlayAnimation}
      startIcon={{ name: IconNames.PlaysmallBold }}
      disabled={!btlyUrl}
      mode={ButtonModes.Secondary}
    >
      {playAnimationMessage}
    </Button>
  );

  return (
    <Layout hideToolbar>
      <SEO title={`${t('previewView.title')}`} description={`${t('previewView.seo')}`} />
      {isPreviewDialogOpen && (
        <PreviewDialog
          isOpen={isPreviewDialogOpen}
          onClose={() => updateIsPreviewDialogOpen(false)}
          handleSubmit={handleSubmit}
        />
      )}
      {isAnimationDialogOpen && <AnimationDialog />}
      <div data-testid="preview-view" className={styles.preview}>
        <div className={styles['card-container']}>
          {config?.carousel?.hasNavigationButtons && (
            <div className={styles['prev-button']}>
              {!isFirstSlide && (
                <IconButton
                  variant={IconButtonVariants.Circular}
                  icon={IconNames.ArrowsTailLeftBold}
                  click={() => handlePrevClick(previewSlideIndex)}
                  ariaLabel="Last slide button"
                  iconClasses={styles['icon']}
                  addClass={styles['button']}
                  iconColor={IconButtonBrandColors.Purple}
                  size={10}
                />
              )}
            </div>
          )}
          <div className={styles['carousel-container']}>
            <Carousel
              disableKeyboardControls={true}
              slidesToScroll={1}
              currentSlideIndex={previewSlideIndex}
              onAfterSlide={handleChangeSlide}
              slideClass={styles.slide}
              addClass={styles.carousel}
            >
              {images.length > 0 &&
                images.map((image, index) => {
                  return (
                    <Image
                      src={image.photoZoneTemplate}
                      alt="Front item"
                      pictureClass={styles['preview-image']}
                      addClass={styles.image}
                      lazyLoad={false}
                      key={`preview-image-${index}`}
                    />
                  );
                })}
            </Carousel>
          </div>
          {config?.carousel?.hasNavigationButtons && (
            <div className={styles['next-button']}>
              {!isLastSlide && (
                <IconButton
                  variant={IconButtonVariants.Circular}
                  icon={IconNames.ArrowsTailRightBold}
                  iconColor={IconButtonBrandColors.Purple}
                  size={10}
                  click={() => handleNextButtonClick(previewSlideIndex)}
                  ariaLabel="Next slide button"
                  iconClasses={styles['icon']}
                  addClass={styles['button']}
                />
              )}
            </div>
          )}
        </div>

        <CardSelectBar
          faces={images}
          slideIndex={previewSlideIndex}
          handleIndexChange={handleImageDotClick}
          mode="preview"
        />

        <div className={styles['play-button-mobile']}>{isDigitalGreetings && playAnimationButton}</div>

        <div className={styles['navigation-buttons']}>
          <Button mode={ButtonModes.TextLink} click={goBack} addClass={styles['edit-button']}>
            {t('previewView.edit')}
          </Button>

          <div className={styles['action-buttons']}>
            {isDigitalGreetings && React.cloneElement(playAnimationButton, { addClass: styles['animation-button'] })}
            {config?.preview?.hasEmailButton && (
              <Button
                disabled={isSendingEmail}
                testId="next-button"
                click={() => handleNextButtonClick(previewSlideIndex)}
              >
                {previewSlideIndex < images.length - 1 ? t('previewView.next') : continueMessage}
              </Button>
            )}
          </div>

          {!appState.isSystemErrorOpen && (
            <AddToCart
              basket={basket}
              recentBasket={recentBasket}
              basketError={basketError}
              closeModalOverride={closeModalOverride}
              keepShoppingOverride={keepShoppingOverride}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
