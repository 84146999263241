import { useEffect } from 'react';
import { fabric } from 'fabric';
import { DialogType } from '../global-types/dialog';
import { CanvasDataTypes } from '../utils';

interface UseKeyboardDeleteProps {
  canvas: fabric.Canvas | null | undefined;
  openDeleteConfirmationDialog: (dialogType: DialogType) => void;
}

export const useKeyboardDelete = ({ canvas, openDeleteConfirmationDialog }: UseKeyboardDeleteProps): void => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Delete') {
        const activeObject = canvas?.getActiveObject();
        if (activeObject) {
          let type: DialogType;

          switch (activeObject?.data?.type) {
            case CanvasDataTypes.UserText:
              type = DialogType.Text;
              break;
            case CanvasDataTypes.StickerImage:
              type = DialogType.Sticker;
              break;
            case CanvasDataTypes.UserImage:
              type = DialogType.Image;
              break;
            default:
              type = DialogType.Text;
              break;
          }

          openDeleteConfirmationDialog(type);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [canvas, openDeleteConfirmationDialog]);
};
